import { isDefined } from "@clipboard-health/util-ts";
import { CbhFeatureFlag, useCbhFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { DocumentVerificationProcessVariants } from "@src/appV2/FeatureFlags/CbhFeatureFlags";
import { useToast } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS, logError, logEvent } from "@src/appV2/lib/analytics";
import {
  startStripeIdentitySession,
  StripeIdentityVerificationFlowStatus,
} from "@src/appV2/Stripe";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

import { DocumentUIVariant, SEGMENT_DOCUMENT_CONTEXT } from "../../Documents/constants";
import { type HcpRequirement } from "../../Documents/types";
import { useDeleteDocument } from "../api/useDeleteDocument";
import { useUploadAndGetVerificationSession } from "./useUploadAndGetVerificationSession";

export interface StripeVerificationFlowProps {
  personalIdSubtype?: string;
  isRequirementPersonalId: boolean;
}

export interface StartStripeVerificationFlowProps {
  personalIdSubtype?: string;
  selectedRequirement?: HcpRequirement;
}

interface StripeVerificationFlowResponse {
  stripeVerificationStatus: StripeIdentityVerificationFlowStatus;
}

/*
  For certain documents, we want to use Stripe Identity to auto-verify them
  For this we use the stripe verification flow, the logic of which is handled by this hook
  Docs here: https://clipboardhealth.atlassian.net/wiki/spaces/EPP/pages/2551054487/Product+Brief+-+automating+ID+verification
 */
export function useStripeVerificationFlow(props: StripeVerificationFlowProps) {
  const { personalIdSubtype, isRequirementPersonalId } = props;
  const ldFlags = useCbhFlags();
  const { showErrorToast } = useToast();

  const documentVerificationProcess = useCbhFlag(CbhFeatureFlag.DOCUMENT_VERIFICATION_PROCESS, {
    defaultValue: DocumentVerificationProcessVariants.INTERNAL,
  });

  const isAutoVerificationDocumentCleanupEnabled = useCbhFlag(
    CbhFeatureFlag.AUTO_VERIFICATION_DOCUMENT_CLEANUP_ENABLED,
    { defaultValue: false }
  );

  const personalVerificationDocumentOptions = ldFlags[CbhFeatureFlag.PERSONAL_ID_SUBTYPES];

  const worker = useDefinedWorker();

  const { uploadAndGetVerificationSession, isLoadingStripeVerificationSession } =
    useUploadAndGetVerificationSession();
  const { mutateAsync: deleteDocument } = useDeleteDocument();

  /* The conditions for a requirement to be in a Stripe auto-verification flow:
      1) CbhFeatureFlag.DOCUMENT_VERIFICATION_PROCESS flag is set to STRIPE_IDENTIFY_SDK
      2) Requirement must be Personal ID
      3) Personal ID subtype must exist and
      4) Personal ID subtype must be one of the subtypes defined in the feature flag CbhFeatureFlag.PERSONAL_ID_SUBTYPES
   */
  const isStripeVerificationFlow =
    documentVerificationProcess === DocumentVerificationProcessVariants.STRIPE_IDENTIFY_SDK &&
    isRequirementPersonalId &&
    isDefined(personalIdSubtype) &&
    isDefined(personalVerificationDocumentOptions) &&
    personalVerificationDocumentOptions.stripe.includes(personalIdSubtype);

  const startStripeVerificationFlow = async (
    props: StartStripeVerificationFlowProps
  ): Promise<StripeVerificationFlowResponse> => {
    try {
      const { personalIdSubtype, selectedRequirement } = props;

      if (
        !isStripeVerificationFlow ||
        !isDefined(selectedRequirement) ||
        !isDefined(personalIdSubtype)
      ) {
        return { stripeVerificationStatus: StripeIdentityVerificationFlowStatus.PENDING };
      }

      const { documentId, clientSecret, verificationSessionId, ephemeralKeySecret } =
        await uploadAndGetVerificationSession({
          hcpId: worker.userId,
          selectedRequirement,
          personalIdSubtype,
        });

      logEvent(APP_V2_APP_EVENTS.STRIPE_IDENTITY_FLOW_BEGUN, {
        context: SEGMENT_DOCUMENT_CONTEXT,
        ui_variant: DocumentUIVariant.V2,
      });

      const stripeVerificationStatus = await startStripeIdentitySession({
        clientSecret,
        verificationSessionId,
        ephemeralKeySecret,
      });

      if (
        stripeVerificationStatus !== StripeIdentityVerificationFlowStatus.COMPLETED &&
        isAutoVerificationDocumentCleanupEnabled
      ) {
        try {
          await deleteDocument(documentId);
        } catch (error) {
          logError(APP_V2_APP_EVENTS.DELETE_DOCUMENT_AFTER_STRIPE_IDENTITY_FAILURE, { error });
        }
      }

      if (stripeVerificationStatus === StripeIdentityVerificationFlowStatus.CANCELED) {
        showErrorToast("You have canceled the Stripe verification process, please try again");
      }

      if (stripeVerificationStatus === StripeIdentityVerificationFlowStatus.FAILED) {
        showErrorToast("Something went wrong. Please try again later!");
      }

      logEvent(APP_V2_APP_EVENTS.STRIPE_IDENTITY_FLOW_COMPLETED, {
        context: SEGMENT_DOCUMENT_CONTEXT,
        verificationSessionId,
        success: stripeVerificationStatus === StripeIdentityVerificationFlowStatus.COMPLETED,
        ui_variant: DocumentUIVariant.V2,
      });

      return { stripeVerificationStatus };
    } catch (error) {
      logError(APP_V2_APP_EVENTS.STRIPE_IDENTITY_VERIFICATION_SESSION_FAILURE, {
        error,
      });
      showErrorToast("Something went wrong. Please try again later!");
      return { stripeVerificationStatus: StripeIdentityVerificationFlowStatus.FAILED };
    }
  };

  return {
    personalIdSubtypes: [
      ...(personalVerificationDocumentOptions.stripe ?? []),
      ...(personalVerificationDocumentOptions.manual ?? []),
    ],
    stripePersonalIdSubtypes: personalVerificationDocumentOptions.stripe ?? [],
    startStripeVerificationFlow,
    isLoadingStripeVerificationSession,
    isStripeVerificationFlow,
    // TODO: Remove this
    isStripeReady: true,
  };
}
