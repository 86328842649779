import { isDefined } from "@clipboard-health/util-ts";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useEffect } from "react";

import { DocumentUIVariant } from "../../Documents/constants";
import { DocumentRequirementType, type HcpRequirement } from "../../Documents/types";
import { useGetProfessionalReference } from "../api/useGetProfessionalReferences";
import { ProfessionalReferenceStatus, ReferenceVariants } from "../types";

export function useLogDocumentDetailsEvent(props: {
  selectedRequirement?: HcpRequirement;
  documentRequirementType?: DocumentRequirementType;
}) {
  const { selectedRequirement, documentRequirementType } = props;
  const { data: professionalReferences } = useGetProfessionalReference({
    enabled: isDefined(selectedRequirement),
  });

  useEffect(() => {
    if (!isDefined(selectedRequirement) || !isDefined(professionalReferences)) {
      return;
    }

    const additionalParameters =
      professionalReferences.references.length > 0 &&
      documentRequirementType === DocumentRequirementType.COMPLETED
        ? {
            references_variant: ReferenceVariants.V2_AUTOMATED,
            count_references_pending: professionalReferences.references?.filter(
              ({ status }) => status === ProfessionalReferenceStatus.REQUESTED
            ).length,
            count_reference_completed: professionalReferences.references?.filter(
              ({ status }) => status === ProfessionalReferenceStatus.VERIFIED
            ).length,
            count_reference_rejected: professionalReferences.references?.filter(
              ({ status }) => status === ProfessionalReferenceStatus.DECLINED
            ).length,
          }
        : {};

    logEvent(APP_V2_USER_EVENTS.DOCUMENT_DETAILS_SCREEN_VIEWED, {
      requirement_id: selectedRequirement.reqId,
      requirement_name: selectedRequirement.name,
      ui_variant: DocumentUIVariant.V2,
      ...additionalParameters,
    });
  }, [selectedRequirement, professionalReferences, documentRequirementType]);
}
