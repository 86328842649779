import { useHistory } from "react-router-dom";

import { DocumentRequirementType, type HcpRequirement } from "../../Documents/types";
import { useCreateDocument } from "../api/useCreateDocument";
import { useUploadFileToFileStorage } from "../api/useUploadFileToFileStorage";
import { DocumentUploadCompletedModalButtonText } from "../components/documentUploadModals/constants";
import { type UploadFileData } from "../types";
import { useDocumentDetailsContext } from "../useDocumentDetails";
import { getDocumentFileName } from "../utils";

interface UploadAndCreateDocumentProps {
  hcpId: string;
  file: UploadFileData;
  requirementId: string;
  selectedRequirement: HcpRequirement;
  personalIdSubtype?: string;
}

export function useUploadAndCreateDocument() {
  const history = useHistory();

  const {
    documentCompletedModalState,
    setDocumentCompletedModalProps,
    viewDocumentOnClick,
    refetchDocumentProperties,
  } = useDocumentDetailsContext();

  const { mutateAsync: uploadFileToFileStorage, isLoading: isLoadingUploadFileToFileStorage } =
    useUploadFileToFileStorage();
  const { mutateAsync: createDocument, isLoading: isLoadingCreateDocument } = useCreateDocument({
    onSuccess: async (data) => {
      setDocumentCompletedModalProps({
        isApproved: false,
        viewDocumentButtonText: DocumentUploadCompletedModalButtonText.VIEW_DOCUMENT,
        onButtonCtaClick: async () => {
          await viewDocumentOnClick(data.data.createHcpDocument._id);
        },
      });
      documentCompletedModalState.openModal();

      const params = new URLSearchParams(window.location.search);
      params.set("requirementStatus", DocumentRequirementType.PENDING);
      history.replace({ search: params.toString() });
      await refetchDocumentProperties({ includeRejectedDocumentNotification: false });
    },
  });

  const isLoading = isLoadingUploadFileToFileStorage || isLoadingCreateDocument;

  const uploadAndCreateDocument = async (props: UploadAndCreateDocumentProps) => {
    const { hcpId, file, requirementId, selectedRequirement, personalIdSubtype } = props;

    const { fileStorageFileKey, fileStorageRootFolder } = await uploadFileToFileStorage({
      requirementId,
      file,
    });
    const fileName = fileStorageFileKey?.split("/").pop();
    return await createDocument({
      hcpId,
      name: getDocumentFileName({ requirementName: selectedRequirement.name, fileName }),
      fileStorageFileKey,
      fileStorageRootFolder,
      subType: personalIdSubtype ?? "",
      uploadedFor: selectedRequirement.reqId,
    });
  };

  return {
    uploadAndCreateDocument,
    isLoading,
  };
}
