import { RequirementWorkflowType } from "../../Documents/resources/requirements/constants";
import { type HcpRequirement } from "../../Documents/types";

interface UseGetHelloSignRequirementProps {
  selectedRequirement?: HcpRequirement;
}

export function useGetHelloSignRequirement(props: UseGetHelloSignRequirementProps) {
  const { selectedRequirement } = props;

  const { requirementWorkflowType, helloSignConfig, childRequirements } = selectedRequirement ?? {};

  const isHelloSignRequirement =
    requirementWorkflowType === RequirementWorkflowType.SIGN_WITH_HELLOSIGN;
  const isCompositeRequirement = requirementWorkflowType === RequirementWorkflowType.COMPOSITE;

  function findHelloSignRequirementInChildRequirements() {
    return childRequirements?.find(
      ({ requirementWorkflowType }) =>
        requirementWorkflowType === RequirementWorkflowType.SIGN_WITH_HELLOSIGN
    );
  }

  function getHelloSignConfig() {
    if (isHelloSignRequirement) {
      return helloSignConfig;
    }

    if (isCompositeRequirement) {
      return findHelloSignRequirementInChildRequirements()?.helloSignConfig;
    }

    return undefined;
  }

  return {
    isHelloSignRequirement,
    getHelloSignConfig,
    findHelloSignRequirementInChildRequirements,
  };
}
